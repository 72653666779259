import React from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/containers';
import { OptionallyVisible } from 'components/optionallyVisible';

import { formatDateLocal } from 'utils/date';
import { capitalizeFirstLetter } from 'utils/string';

import { KEYS, LABELS } from '../../keys';
import { OrderPwcSectionProps } from '../../types';
import { Item, ItemName, ItemValue, OnChainTransactionHash, SectionWrapper } from '../styles';

interface ExchangeDetailsSectionProps extends OrderPwcSectionProps {
  hasExtendedDetails: boolean;
}

export const ExchangeDetailsSection = ({ orderData, hasExtendedDetails }: ExchangeDetailsSectionProps) => {
  const { t } = useTranslation();

  const { cryptoExchange } = orderData;

  const isBankTransaction = Boolean(cryptoExchange?.bankAccount);
  const isChainTransaction = Boolean(cryptoExchange?.crypto);

  return (
    <Panel label={t(LABELS.SECTIONS.EXCHANGE_DETAILS.TITLE)}>
      <SectionWrapper>
        <Item>
          <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.LIQUIDITY_PROVIDER)}</ItemName>
          <ItemValue>
            {cryptoExchange?.liquidityProvider ? capitalizeFirstLetter(cryptoExchange?.liquidityProvider) : t(KEYS.DASH)}
          </ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.DEPOSIT_ADDRESS)}</ItemName>
          <ItemValue>{cryptoExchange?.toWalletAddress}</ItemValue>
        </Item>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.DEPOSIT_MEMO)}</ItemName>
          <ItemValue>{cryptoExchange?.toWalletMemo}</ItemValue>
        </Item>

        <OptionallyVisible visible={Boolean(cryptoExchange?.depositReceivedAt)}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.DEPOSIT_RECEIVED_TIME)}</ItemName>
            <ItemValue>{formatDateLocal(cryptoExchange!.depositReceivedAt!)}</ItemValue>
          </Item>
        </OptionallyVisible>

        <Item>
          <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.EXCHANGE_STATUS)}</ItemName>
          <ItemValue>{cryptoExchange?.exchangeStatus}</ItemValue>
        </Item>

        <OptionallyVisible visible={hasExtendedDetails}>
          <Item>
            <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.SETTLEMENT_PAYMENT_RAIL)}</ItemName>
            <ItemValue>{cryptoExchange?.settlementPaymentRail?.toUpperCase()}</ItemValue>
          </Item>

          <Item>
            <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.SETTLEMENT_CURRENCY)}</ItemName>
            <ItemValue>{cryptoExchange?.settlementCurrency?.toUpperCase()}</ItemValue>
          </Item>

          <OptionallyVisible visible={isBankTransaction}>
            <Item>
              <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.BANK_ACCOUNT_NAME)}</ItemName>
              <ItemValue>{cryptoExchange?.bankAccount?.bankName}</ItemValue>
            </Item>
          </OptionallyVisible>

          <OptionallyVisible visible={isChainTransaction}>
            <Item>
              <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.DESTINATION_WALLET)}</ItemName>
              <ItemValue>{cryptoExchange?.crypto?.wallet}</ItemValue>
            </Item>
            <Item>
              <ItemName>{t(LABELS.SECTIONS.EXCHANGE_DETAILS.SETTLEMENT_TRANSACTION_HASH)}</ItemName>
              <OnChainTransactionHash displayFullHash>
                <a href={cryptoExchange?.crypto?.onChain?.explorerUrl} target="_blank">
                  {cryptoExchange?.crypto?.onChain?.transactionHash}
                </a>
              </OnChainTransactionHash>
            </Item>
          </OptionallyVisible>
        </OptionallyVisible>
      </SectionWrapper>
    </Panel>
  );
};
