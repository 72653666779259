import { createApi } from '@reduxjs/toolkit/query/react';
import { Endpoint } from 'services/endpoints';

import { HTTPMethod, Response, SuccessResponse } from 'types/http';

import { getDefaultPagination } from '../../utils/pagination';
import { fetchBaseQueryWithAuth } from '../api';
import { TAGS } from './keys';
import {
  CreateBankAccountPayload,
  CreateMerchantBankAccountResponse,
  MerchantInfo,
  MerchantsBanksResponse,
  MerchantsInfoResponse,
  MerchantsListQuery,
  MerchantsListResponse,
  SimulateDepositResponse,
} from './types';
import { decorateUpdateRequest, undecorateMerchantData } from './utils';

const REDUCER_PATH = 'merchantsApi';

export const merchantsApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQueryWithAuth,
  tagTypes: [TAGS.MERCHANT_LIST, TAGS.MERCHANT_BANK_ACCOUNTS_LIST],
  endpoints: (builder) => ({
    getMerchantsList: builder.query<MerchantsListResponse, MerchantsListQuery>({
      query: (requestBody) => ({
        url: Endpoint.Merchants.List,
        method: HTTPMethod.POST,
        body: requestBody,
      }),
      transformResponse: (response: Response<MerchantsListResponse>) => {
        if (!response?.data) {
          return { data: [], pagination: getDefaultPagination(), requestId: response.requestId };
        }

        return response.data;
      },
      providesTags: [TAGS.MERCHANT_LIST],
    }),
    getMerchant: builder.query<MerchantsInfoResponse, string>({
      query: (uuid) => Endpoint.Merchants.ById(uuid),
      forceRefetch: () => true,
      transformResponse: (response: MerchantsInfoResponse) => ({
        data: response.data ? undecorateMerchantData(response?.data) : (response.data as MerchantInfo),
      }),
    }),
    updateMerchant: builder.mutation<Response<SuccessResponse>, Partial<MerchantInfo>>({
      query: (requestBody) => ({
        url: Endpoint.Merchants.ById(requestBody.uuid!),
        method: HTTPMethod.PUT,
        body: decorateUpdateRequest(requestBody),
      }),
    }),
    getMerchantsBankAccounts: builder.query<MerchantsBanksResponse, string>({
      query: (uuid) => Endpoint.Merchants.BankAccountsById(uuid),
      providesTags: [TAGS.MERCHANT_BANK_ACCOUNTS_LIST],
    }),
    createMerchantsBankAccount: builder.mutation<CreateMerchantBankAccountResponse, CreateBankAccountPayload>({
      query: ({ uuid, ...body }) => ({
        url: Endpoint.Merchants.BankAccountsById(uuid!),
        method: HTTPMethod.POST,
        body,
      }),
      invalidatesTags: (result, error) => (error ? [] : [TAGS.MERCHANT_BANK_ACCOUNTS_LIST]),
    }),
    simulateDeposit: builder.mutation<SimulateDepositResponse, string>({
      query: (uuid) => ({
        url: Endpoint.Merchants.simulatePayout(uuid!),
        method: HTTPMethod.POST,
      }),
    }),
  }),
});

export const { getMerchantsList, getMerchant } = merchantsApi.endpoints;
export const {
  useGetMerchantsListQuery,
  useGetMerchantQuery,
  useUpdateMerchantMutation,
  useGetMerchantsBankAccountsQuery,
  useCreateMerchantsBankAccountMutation,
  useSimulateDepositMutation,
} = merchantsApi;
export default merchantsApi;
